import { render, staticRenderFns } from "./RTShow.vue?vue&type=template&id=2860c1f3"
import script from "./RTShow.vue?vue&type=script&lang=js"
export * from "./RTShow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports